import { Box, Heading, useDisclosure, useToast } from '@chakra-ui/react';
import { PaperPlaneTilt } from 'phosphor-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import BoxSection from '../../../components/BoxSection';
import BoxStyled from '../../../components/BoxStyled';
import Button from '../../../components/Button';
import Container from '../../../components/Container';
import Drawer, { IType } from '../../../components/Drawer';
import Filter from '../../../components/Filter';
import Nav from '../../../components/Navbar';
import PaginatedTable from '../../../components/PaginatedTable';
import { COMPANY_ADMIN_DATA } from '../../../constants';
import { useAuth } from '../../../hooks/Auth';
import { useChangeCompany } from '../../../hooks/ChangeCompany';
import { ICompany } from '../../../interfaces/Company';
import {
  createAdministratorValidationSchema,
  editAdministratorValidationSchema,
} from '../../../schemas/AdministratorSchema';
import api, { Service } from '../../../services/api';
import ErrorHandler from '../../../utils/errorHandler';
import { promisify } from '../../../utils/promisify';
import { adminFields, adminFilterFields } from './fields';
import { useLocalStorage } from '../../../hooks/LocalStorage';
import { IUser } from '../../../interfaces/User';
import { USER_COOKIE_NAME } from '../../../constants';



interface ICompanyRef {
  name: string | null;
  value: string | number | null;
}

interface FiltersProps {
  firstName?: string;
  lastName?: string;
  email?: string;
  company?: string;
  isActive?: boolean;
}

const Administrators: React.FC = () => {
  const [requestLoad, setRequestLoad] = useState(true);
  const [filters, setFilters] = useState<FiltersProps>();
  const [companies, setCompanies] = useState([] as Array<ICompanyRef>);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [drawerData, setDrawerData] = useState({});
  const [, , getLocalStorage] = useLocalStorage('', '');
  const [drawerType, setDrawerType] = useState<IType>({
    fields: [],
    action: () => {},
    validationSchema: undefined,
  } as IType);

  const { user } = useAuth();
  const { company, userIsKlever, getUserCompany } = useChangeCompany();

  const toast = useToast();
  const theme = useTheme();
  const disclosure = useDisclosure();
  const { t } = useTranslation('adminAdministrators');
  const { t: drawerT } = useTranslation('drawer');

  const memoizedFields = useMemo(() => {
    return {
      ...adminFields,
      create: [
        ...adminFields.create,
        {
          key: 'companyId',
          type: 'select',
          disabled: false,
          required: true,
          default: userIsKlever ? '0' : getUserCompany.id,
          selectOptions: [
            {
              value: '0',
              name: drawerT('selectDefault'),
            },
            ...companies,
          ],
        },
      ],
      edit: [
        ...adminFields.edit,
        {
          key: 'companyId',
          type: 'select',
          disabled: false,
          required: true,

          selectOptions: [
            {
              name: drawerT('selectDefault'),
              value: false,
            },
            ...companies,
          ],
        },
      ],
    };
  }, [adminFields, companies]);

  const getCompanies = useCallback(async () => {
    let allCompanies = [
      {
        name: getUserCompany.name,
        value: getUserCompany.id,
      },
    ] as Array<ICompanyRef>;

    if (userIsKlever && !companies.length) {
      const { data } = await api.get({
        route: 'companies',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        query: { limit: 100 },
      });

      if (data?.length) {
        allCompanies = data
          .filter((c: ICompany) => c.name !== COMPANY_ADMIN_DATA.name)
          .map((c: ICompany) => ({
            name: c.name,
            value: c.id,
          }));
      }
    }

    setCompanies(allCompanies);
  }, [companies, userIsKlever, getUserCompany]);

  const createUser = async (invitedUser: any) => {
    if (!invitedUser.companyId) invitedUser.companyId = getUserCompany.id;

    if (invitedUser.isKleverAdmin && invitedUser.companyId !== COMPANY_ADMIN_DATA.id)
      invitedUser.companyId = getUserCompany.id;

    const session = getLocalStorage(USER_COOKIE_NAME) || '';

    if (session) {
        const user = JSON.parse(session) as IUser;
    }


    const response = await api.post({
      route: `users/invite`,
      service: Service.KRYPTO_BANKING,
      apiVersion: 'v1',
      body: {
        ...invitedUser,
        companyId: Number(invitedUser.companyId),
        invitedFrom: user.id,
      },
    });

    if (response?.message) {
      toast({
        title: t('common:toasts.titles.error'),
        description: ErrorHandler('adminAdministrators', response.message),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    toast({
      title: t('common:toasts.titles.success'),
      description: t('successfullySent'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const updateUser = async (user: any) => {
    const { id, companyId, email, firstName, lastName } = user;

    const payload = {
      id,
      companyId,
      email,
      firstName,
      lastName,
      isKleverAdmin: {
        bool: user.isKleverAdmin,
        valid: true,
      },
      isActive: {
        bool: user.isActive,
        valid: true,
      },
    };

    const response = await api.patch({
      route: `users/${id}`,
      service: Service.KRYPTO_BANKING,
      apiVersion: 'v1',
      body: payload,
    });

    if (response?.message || response.code === 'internal_error') {
      toast({
        title: t('common:toasts.titles.error'),
        description: ErrorHandler(
          'adminAdministrators',
          response.message ?? 'internal_error',
        ),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    toast({
      title: t('common:toasts.titles.success'),
      description: t('successfullyUpdated'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const deleteUser = async (user: any) => {
    const response = await api.delete({
      route: `users/${user.id}`,
      service: Service.KRYPTO_BANKING,
      apiVersion: 'v1',
    });

    if (response?.message || response.code === 'internal_error') {
      toast({
        title: t('common:toasts.titles.error'),
        description: ErrorHandler(
          'adminAdministrators',
          response.message ?? 'internal_error',
        ),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    toast({
      title: t('common:toasts.titles.success'),
      description: t('successfullyUpdated'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const handleChangeFilters = (e: object) => setFilters(e);

  useEffect(() => void getCompanies(), []);

  useEffect(() => {
    (async () => {
      if (company.name && user.isKleverAdmin) {
        setRequestLoad(true);
        await promisify(() => setRequestLoad(false));
        return;
      }
    })();
  }, [company]);

  return (
    <Container>
      <Nav />
      <Drawer
        title={drawerTitle}
        useDisclosureProps={disclosure}
        type={drawerType}
        data={drawerData}
      />
      <BoxStyled>
        <BoxSection>
          <Box display={'flex'} flexDirection={'column'}>
            <Heading
              textAlign={'center'}
              color={theme.common.lightText}
              mb="1rem"
            >
              {!company.name && !user.isKleverAdmin
                ? t('title')
                : `${t('titleCompanyChanged')} ${
                    company.name || user.companyName
                  }`}
            </Heading>
            <Button
              className="send-invite"
              buttonType="primary"
              alignSelf={['center', 'center', 'center', 'end', 'end']}
              mt={['0rem', '0rem', '0rem', '0rem', '-4rem']}
              mb="1.5rem"
              gap="0.5rem"
              maxWidth="205px"
              onClick={() => {
                setDrawerData({});
                setDrawerTitle(t('drawer.create'));
                let selectedFields: any = memoizedFields.create;
                const action: any = createUser;

                const validationSchema: any =
                  createAdministratorValidationSchema(company);

                if (!userIsKlever) {
                  delete selectedFields[3];
                  delete selectedFields[4];
                }

                setDrawerType({
                  fields: selectedFields.filter(Boolean),
                  validationSchema,
                  action: action,
                });

                return disclosure.onOpen();
              }}
            >
              <PaperPlaneTilt size={20} />

              {t('newAdministrator')}
            </Button>
          </Box>

          <Filter
            translation="adminAdministrators"
            onSubmit={handleChangeFilters}
            fields={adminFilterFields}
          />
        </BoxSection>
        <PaginatedTable
          endpoint="users"
          headers={[
            'firstName',
            'lastName',
            'email',
            'Company',
            'isKleverAdmin',
            'isActive',
          ]}
          ns="administratorsTable"
          shouldReload={!disclosure.isOpen}
          viewAction={(administrator: any) => {
            setDrawerData(administrator);
            setDrawerTitle(t('drawer.view'));
            const selectedFields = memoizedFields.view;
            setDrawerType((prev: any) => ({
              ...prev,
              fields: selectedFields,
              action: {
                name: 'view',
              },
            }));
            return disclosure.onOpen();
          }}
          editAction={(administrator: any) => {
            setDrawerData(administrator);
            setDrawerTitle(t('drawer.edit'));
            const selectedFields: any = memoizedFields.edit;
            const action: any = updateUser;
            const validationSchema: any = editAdministratorValidationSchema;

            setDrawerType({
              fields: selectedFields,
              action: action,
              validationSchema,
            });

            return disclosure.onOpen();
          }}
          deleteAction={deleteUser}
          requestLoad={requestLoad}
          filters={filters}
        />
      </BoxStyled>
    </Container>
  );
};

export default Administrators;
