export const TOKEN_COOKIE_NAME = '@krypto-banking/token';
export const REFRESH_TOKEN_COOKIE_NAME = '@krypto-banking/refreshToken';
export const USER_COOKIE_NAME = '@krypto-banking/user';
export const USER_COMPANY_COOKIE_NAME = '@krypto-banking/companyProfile';

export const ABLED_BUTTON_SEND_TICKET_MODAL = ['failed', 'done'];

export const BLOCKED_ATTRIBUTES = [
  'user_id',
  'companyId',
  'isKleverAdmin',
  'Company',
];
export const ALLOWED_ADMIN_ROUTES = [
  '/user',
  '/account',
  '/',
  '/index',
  '/login',
  '/logs',
  '/statement',
  '/manage',
];
export const COMPANY_ADMIN_DATA = {
  name: 'Klever',
  email: 'klever@klever.io',
  id: 0,
};

export const LIMIT_CHARACTERS = 500;

export const TYPE_OPTIONS = [
  'deposit',
  'withdraw',
  'charge',
  'settings',
  'billing',
  'users',
  'accounts',
  'tokens',
  'admin_panel',
  'other',
];

export const TYPE_CHAIN = [
  { type: 'TRX', chain_value: 1 },
  { type: 'BTC', chain_value: 2 },
  { type: 'ETH', chain_value: 3 },
  { type: 'TKLV', chain_value: 33 },
  { type: 'KLV', chain_value: 38 }

  //! next chain to be included in future
  /*
    { type: "BCH", chain_value: 8 },
    { type: "BNB", chain_value: 7 },
    { type: "BSC", chain_value: 6 },
    { type: "COSMOS", chain_value: 7 },
    { type: "DASH", chain_value: 1 },
    { type: "DGB", chain_value: 6 },
    { type: "DOGE", chain_value: 2 },
    { type: "EOS", chain_value: 8 },
    { type: "KLAY", chain_value: 0 },
    { type: "LTC", chain_value: 5 },
    { type: "ONTOLOGY", chain_value: 9 },
    { type: "POLYGON", chain_value: 8 },
    { type: "STELLAR", chain_value: 6 },
    { type: "SYS", chain_value: 5 },
    { type: "XRP", chain_value: 4 },
    */
];

export const LANGUAGE_NAME = 'lng';
export const THEME_NAME = '@krypto-banking/theme';
export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const FULL_NAME_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const LOCAL_STORAGE_KEYS = {
  totalByTokenAndDate: '@krypto-banking/total-by-token-and-date',
  customerTokens: '@krypto-banking/customer-tokens',
  totalByDate: '@krypto-banking/total-by-date',
  totalQuantityBydate: '@krypto-banking/total-quantity-by-date',
};

export const NUMBER_DAYS = 30;
export const TIMESTAMP_30DAYS = 2592000000;
