import { Box, Heading, useDisclosure, useToast } from '@chakra-ui/react';
import { PencilSimple } from 'phosphor-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import BoxSection from '../../../components/BoxSection';
import BoxStyled from '../../../components/BoxStyled';
import Button from '../../../components/Button';
import Container from '../../../components/Container';
import Drawer, { IType } from '../../../components/Drawer';
import Nav from '../../../components/Navbar';
import PaginatedTable from '../../../components/PaginatedTable';
import { createAndEditCompanyValidationSchema } from '../../../schemas/CompaniesSchema';
import api, { Service } from '../../../services/api';
import ErrorHandler from '../../../utils/errorHandler';
import { companyFields } from './fields';

const Administrators: React.FC = () => {
  const toast = useToast();
  const { t } = useTranslation('adminCompanies');
  const theme = useTheme();
  const disclosure = useDisclosure();
  const [drawerTitle, setDrawerTitle] = useState('');
  const [drawerType, setDrawerType] = useState({
    fields: [],
    action: () => {},
    validationSchema: undefined,
  } as IType);
  const [drawerData, setDrawerData] = useState({});

  const updateCompany = async (company: any) => {
    const response = await api.patch({
      route: `companies/${company.id}`,
      service: Service.KRYPTO_BANKING,
      apiVersion: 'v1',
      body: { ...company },
    });
    if (response?.message) {
      return toast({
        title: t('common:toasts.titles.error'),
        description: ErrorHandler('adminCompanies', response.message),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      return toast({
        title: t('common:toasts.titles.success'),
        description: t('successfullyUpdated'),
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const createCompany = async (company: any) => {
    const response = await api.post({
      route: 'companies',
      service: Service.KRYPTO_BANKING,
      apiVersion: 'v1',
      body: { ...company },
    });
    if (response?.message) {
      return toast({
        title: t('common:toasts.titles.error'),
        description: ErrorHandler('adminAdministrators', response.message),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      return toast({
        title: t('common:toasts.titles.success'),
        description: t('successfullyCreated'),
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Container>
      <Nav />
      <Drawer
        title={drawerTitle}
        useDisclosureProps={disclosure}
        type={drawerType}
        data={drawerData}
      />
      <BoxStyled>
        <BoxSection>
          <Box display={'flex'} flexDirection={'column'}>
            <Heading
              textAlign={'center'}
              color={theme.common.lightText}
              mb="1rem"
            >
              {t('title')}
            </Heading>
            <Button
              buttonType="primary"
              gap="0.5rem"
              alignSelf={['center', 'center', 'center', 'end', 'end']}
              mt={['0rem', '0rem', '0rem', '0rem', '-4rem']}
              mb="1.5rem"
              onClick={() => {
                setDrawerData({});
                setDrawerTitle(t('drawer.create'));
                const selectedFields = companyFields.create;
                const action: any = createCompany;
                const validationSchema: any =
                  createAndEditCompanyValidationSchema;
                setDrawerType({
                  fields: selectedFields,
                  validationSchema,
                  action: action,
                });
                return disclosure.onOpen();
              }}
            >
              <PencilSimple size={20} weight="duotone" />

              {t('newCompany')}
            </Button>
          </Box>
        </BoxSection>
        <PaginatedTable
          endpoint="companies"
          headers={['name', 'email', 'address']}
          ns="companiesTable"
          shouldReload={!disclosure.isOpen}
          viewAction={(company: any) => {
            setDrawerData(company);
            setDrawerTitle(t('drawer.view'));
            const selectedFields: any = companyFields.view;

            setDrawerType((prev: any) => ({
              ...prev,
              fields: selectedFields,
              action: {
                name: 'view',
              },
            }));

            return disclosure.onOpen();
          }}
          editAction={(company: any) => {
            setDrawerData(company);
            setDrawerTitle(t('drawer.edit'));
            const selectedFields: any = companyFields.edit;
            const action: any = updateCompany;
            const validationSchema: any = createAndEditCompanyValidationSchema;
            setDrawerType({
              fields: selectedFields,
              action: action,
              validationSchema,
            });
            return disclosure.onOpen();
          }}
          editModalOpened={disclosure.isOpen}
        />
      </BoxStyled>
    </Container>
  );
};

export default Administrators;
